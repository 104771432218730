body{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  
}


/* Default styles (for large devices like desktops) */
.book-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* Medium devices (tablets, small laptops) */
@media (min-width: 1024px) {

  body{
  }
}
a{
  color: black;
}

/* Small devices (phones) */
@media (min-width: 600px) {
  body{
  }
}
.logo{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  
}
.logo-image{
  width: 50px;
  height: 50px;
  margin-right: 10px;

}
.logo-text{
  font-size: 18px;
  color: #9b9b9b;
  font-size: 15px;
}
.search-container {
  display: flex;
  align-items: center;
  background-color: #ffffff; /* Màu nền */
  padding: 10px 20px; /* Khoảng cách bên trong */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Đổ bóng */
  margin-bottom: 20px; /* Khoảng cách giữa bảng và bảng */
  margin-top: 20px;
  width: 100%;
  max-width: 500px;
  height: 50px;
  position: relative;

}
.search-container input {
  border: #f2fcfb;
}

.search-icon {
  font-size: 18px;
  color: #9b9b9b; /* Màu biểu tượng tìm kiếm */
  margin-right: 10px; /* Khoảng cách giữa biểu tượng và ô nhập */
}

.search-input {
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  width: 100%;
  color: #6b6b6b; /* Màu chữ */
}

.search-input::placeholder {
  color: #b0b0b0; /* Màu của placeholder */
}


/* App.css */

/* :root {
  --bg-color-light: #ffffff;
  --text-color-light: #000000;

  --bg-color-dark: #1e1e1e;
  --text-color-dark: #ffffff;
} */

/* Áp dụng cho Light Mode */
/* .light-mode {
  background-color: var(--bg-color-light);
  color: var(--text-color-dark) !important;
} */

/* Áp dụng cho Dark Mode */
/* .dark-mode {
  background-color: var(--bg-color-dark);
  color: var(--text-color-dark) !important;
}

.container {
  padding: 20px;
  text-align: center;
}

button {
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

button.light {
  background-color: #000;
  color: #fff;
}

button.dark {
  background-color: #fff;
  color: #000;
} */
