

.all-comic-update{
    font-family: 'Arial', sans-serif;
    padding: 20px;
}

.pagination{
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f0f4f8;
    padding: 10px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}