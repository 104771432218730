/* Slick Carousel Default Styles */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";



  
  .header-container {
    padding: 10px 0;
    font-size: 1.5rem;
    color: #333;
  }
  .searchbar-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .search-input {
    width: 80%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .filter-button {
    background-color: #333;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
  }
  .tabs-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .tab-button {
    background-color: #e0e5ec;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
  }
  
  .tab-button.active {
    background-color: #333;
    color: white;
  }

  .booklist-container {
    margin-top: 20px;
    width: 100%;
  }
  
  .slick-slide {
    padding: 0 10px;
  }
  

  
  .book-cover {
    height: 100px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 5px;
  }
  
  .book-rating {
    margin-top: 10px;
    font-size: 1rem;
  }
  .book-info {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 10px 10px;
  }
  .header-title {
    font-size: 1.5rem;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  .book-title {
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .book-author {
    font-size: 0.5rem;
  }
  .book-rating {
    font-size: 0.5rem;
  }

  .baner-container {
    margin-top: 30px;
    width: 100%;
    height: 200px;
    background-color: #f0f4f8;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
  }
  
  .baner-image {
    height: 200px;
    width: 100%;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    
  }










  .book-list {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  

  
  .book-card {
    display: flex;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    height: 180px;
    width: 120px;
  }
  .ant-image {
    height: 180px;
    width:100% !important;
  }
  
  .book-cover {
    height: 180px;
    object-fit: cover;
    width: 120px;
  }
  
  .book-info {
    padding: 10px;
    flex: 1;
    height: 100%;
    display: flex;
  }
  
  .book-info h3 {
    font-size: 18px;
    margin: 0;
  }
  
  .book-info p {
    font-size: 14px;
    margin: 5px 0;
    padding: 5px 0;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .book-tags {
    display: flex;
    flex-wrap: wrap;
  }
  
  .book-tag {
    color: #fff;
    border-radius: 5px;
    background: #00000080;
    padding: 5px 5px;
    font-size: 8px;

  }
  
  .book-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    text-align: center;
    height: 250px;
    width: 170px;
    position: relative;
  }
  .book-cover {
    height: 300px;
    width: 200px;
    margin-bottom: 10px;
    border-radius: 5px;
  }


  /* CategoryList.css */

.category-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 20px;
  font-family: Arial, sans-serif;
}

.category-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
  color: #333;
}

.category-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  list-style: none; /* Loại bỏ bullet trong ul */
  padding: 0;
}

.category-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.category-item {
  padding: 10px;
  font-size: 16px;
  color: #0073e6;
  cursor: pointer;
  text-decoration: none; /* Loại bỏ gạch chân của link */
  transition: all 0.3s ease;
  border-radius: 4px;
  text-align: center;
}

.category-item:hover {
  background-color: #0073e6;
  color: white;
  transform: translateY(-3px);
}

.category-item:active {
  transform: translateY(0);
}

  

  .app-container {
    font-family: 'Arial', sans-serif;
    padding-left: 350px;
    padding-right: 350px;
    display: flex;
    /* background-color: #f0f4f8; */
  }

  .book-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
  }

  .image-carousel{
    height: 400px !important;
    object-fit: cover;
  }
  .slick-list{
    height: 400px;
  }
  .ant-image-mask{
    display: none !important;
  }
  .slick-slide{
    padding: 0;
  }
 
  

  @media screen and (max-width: 1280px) {
    .app-container {
      padding-left: 200px;
      padding-right: 200px;
    }
    .category-container {
      display: none;
    }
  }
  @media screen and (max-width: 1024px) {
    .app-container {
      padding: 0;
    }
    .book-card {
      height: 220px;
      width: 150px;
    }
    .book-cover {
      height: 220px;
      width: 150px;

    }
  }
  @media screen and (max-width: 768px) {
    .book-card {
      height: 150px;
      width: 100px;
    }
    .book-cover {
      height: 200px;
      width: 128px;

    }
    .book-grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      
    }
    .image-carousel{
      height: 200px !important;
      object-fit: cover;
    }
    .slick-list{
      height: 200px;
    }
  }
