
  :where(.css-dev-only-do-not-override-ccdg5a).ant-pagination .ant-pagination-item-active{
    border-color: #6760b3 !important;
  }
  :where(.css-dev-only-do-not-override-ccdg5a) a {
    color: #000 !important;
  }
  .product-detail {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  /* Header */
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .back-icon, .options-icon {
    font-size: 1.5rem;
    color: #8f8f8f;
    cursor: pointer;
  }
  
  /* Product Info */
  .product-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .left {
    width: 60%;
    text-align: left;
  }
  
  .left h2 {
    font-size: 1.5rem;
    margin: 0;
    color: #333;
  }
  
  .left .author {
    color: #6760b3;
    margin-bottom: 10px;
    font-size: 0.9rem;
  }
  
  .left h3 {
    margin: 10px 0;
    font-size: 1rem;
  }
  
  .left .synopsis {
    font-size: 0.85rem;
    color: #555;
    line-height: 1.4;
  }
  
  .right {
    width: 40%;
  }
  
  .product-image {
    width: 100%;
    border-radius: 10px;
    height: 240px;
    object-fit: cover;
    margin-bottom: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  }
  
  /* Product Meta */
  .product-meta {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .meta-item {
    text-align: center;
    width: 33%;
  }
  
  .meta-item .meta-icon {
    font-size: 1.2rem;
    color: #000000;
    display: block;
    margin-bottom: 5px;
  }
  
  .meta-item span {
    font-size: 1.1rem;
    font-weight: bold;
  }
  
  .meta-item small {
    font-size: 0.75rem;
  }
  
  /* Add to Cart Button */
  .add-to-cart {
    background-color: #fff !important; 
    color: #000000 !important;
    border: none;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 50px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-to-cart:hover {
    background-color: #3ca892;
  }

  
  .heart-icon {
    margin-right: 10px;
  }
  
  .comic-page {
    padding-left: 350px;
    padding-right: 350px;
  }
  @media screen and (max-width: 1280px) {
    .comic-page {
      padding-left: 100px;
      padding-right: 100px;
    }
  }
  @media screen and (max-width: 1024px) {
    .comic-page {
      padding: 0;;
    }
  }
  @media screen and (max-width: 768px) {
    
  }
