/* Container lò luyện đan */
.alchemy-loader {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 50px auto;
  }
  
  /* Lò luyện đan */
  .cauldron {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 150px;
    height: 100px;
    background: url('https://i.pinimg.com/originals/71/3a/32/713a3272124cc57ba9e9fb7f59e9ab3b.gif') no-repeat center;
    background-size: contain;
    transform: translateX(-50%);
  }
  
  /* Ánh sáng phát ra từ lò */
  .glow {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 120px;
    height: 120px;
    background: radial-gradient(circle, #dfcea5 0%, rgba(0, 0, 0, 0) 70%);
    border-radius: 50%;
    transform: translateX(-50%);
    animation: glow-pulse 2s infinite alternate;
  }
  
  /* Khói bay ra từ lò luyện đan */
  .smoke {
    position: absolute;
    bottom: 80px;
    left: 50%;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    animation: smoke-rise 3s ease-in-out infinite;
    opacity: 0;
  }
  
  /* Hiệu ứng khói bốc lên */
  @keyframes smoke-rise {
    0% {
      transform: translate(-50%, 0) scale(0.5);
      opacity: 0.3;
    }
    50% {
      transform: translate(-50%, -70px) scale(1);
      opacity: 0.6;
    }
    100% {
      transform: translate(-50%, -120px) scale(1.5);
      opacity: 0;
    }
  }
  
  /* Hiệu ứng ánh sáng phát ra */
  @keyframes glow-pulse {
    0% {
      transform: translate(-50%) scale(1);
      opacity: 0.7;
    }
    100% {
      transform: translate(-50%) scale(1.2);
      opacity: 1;
    }
  }
  
  /* Hiệu ứng cho khói bay liên tục */
  .smoke1 {
    animation-delay: 0s;
  }
  .smoke2 {
    animation-delay: 1s;
  }
  .smoke3 {
    animation-delay: 2s;
  }
  