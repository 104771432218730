.pagination-bar {
    display: flex;
    align-items: center;
    justify-content: center; /* Căn giữa thanh điều hướng */
    gap: 10px;
    width: 100%;
}

.pagination-button {
    background-color: #fff; /* Màu nền */
    color: #000; /* Màu chữ */
    border: none;
    border-radius: 5px; /* Bo góc */
    padding: 10px 10px; /* Khoảng cách bên trong */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Hiệu ứng chuyển đổi màu nền */
 
}

.pagination-button:hover {
    background-color: #dbd5d5; /* Màu nền khi hover */
}

.pagination-button:disabled {
    background-color: #fff; /* Màu nền khi vô hiệu hóa */
    cursor: not-allowed; /* Con trỏ không cho phép */
}

.pagination-select {
    padding: 10px; /* Khoảng cách bên trong */
    border: 1px solid #3498db; /* Viền */
    border-radius: 5px; /* Bo góc */
    cursor: pointer; /* Con trỏ khi di chuột */
    background-color: #ffffff; /* Màu nền select */
    border: 1px solid #ffffff; /* Viện */
    width: 100%;
    color: #000;
  
}

.current-page {
    font-size: 16px; /* Kích thước chữ */
    font-weight: bold; /* Đậm chữ */
    margin: 0 ;
}
.chapter-page{
    padding-bottom: 100px;
    padding-left: 300px;
    padding-right: 300px;
}
@media screen and (max-width: 1280px) {
    .chapter-page {
      padding-left: 100px;
      padding-right: 100px;
    }
  }
  @media screen and (max-width: 1024px) {
    .chapter-page {
        padding-bottom: 100px;
        padding-left: 0;
        padding-right: 0;
    }
  }
  @media screen and (max-width: 768px) {
    
  }
